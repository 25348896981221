@import 'styles/includes.scss';
@import 'styles/variables.scss';

.CardSlider {
    $root: &;

    &__Container {
        overflow: visible;
        width: 100%;

        &--Guides {
            .CardSlider__Card {
                height: 28.8rem;
                position: relative;

                .CardGuide_CardGuide__Link {
                    position: static;
                }

                @include media(l) {
                    height: 31.2rem;
                }
            }
        }
    }

    &__Slide {
        width: calc(25% - 1.2rem) !important;
        margin-right: 1.6rem !important;
        min-width: 25.3rem !important;

        &:last-of-type {
            margin-right: 0 !important;
        }
    }

    &__Card {
        max-width: 100%;
        width: 100%;
        text-decoration: none;

        &--Guide {
            height: 28.8rem;
        }
    }

    &__Slider {
        -ms-overflow-style: none;
        position: relative;
        padding-left: 0;
        margin-left: 0;
        display: block;
        overflow: visible !important;
        min-width: 100%;


        @include media(l) {
            padding-top: 0; //54px;
            padding-right: 0;
        }

        div:focus {
            outline: none;
        }
    }

    &__Slider--Link {
        & [aria-disabled='true'] {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__Slider--HideDisabled {
        & [aria-disabled='true'] {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__Button {
        margin-top: 0;
        top: 107px;
        left: 5px;
        right: auto;
        height: 42px;
        width: 42px;
        display: flex;
        justify-content: center;
        color: $colorWhite;
        border-radius: 50%;
        background-color: $colorPink;
        z-index: 10;
        position: absolute;

        &[aria-disabled="false"]:hover {
            cursor: pointer;
        }

        @include media(s) {
            height: 48px;
            width: 48px;
            left: 0;
        }

        @include media(l) {
            justify-content: center;
            align-items: center;
            top: -64px;
            height: 32px;
            width: 32px;
            left: calc(
                (((100% - #{map-get($maxwidths, l)}) / 2) + #{map-get(
                                $maxwidths,
                                l
                            )}) - 84px
            );
        }

        @include media(xl) {
            left: calc(
                (((100% - #{map-get($maxwidths, xl)}) / 2) + #{map-get(
                                $maxwidths,
                                xl
                            )}) - 84px
            );
        }

        &::before {
            color: $colorWhite;
            content: '';
            background-image: url('#{$assetPath}img/chevron_white.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: block;
            margin: auto;
            opacity: 1;
            z-index: 10;
            height: 38px;
            width: 38px;

            @include media(l) {
                height: 24px;
                width: 24px;
            }
        }

        &[aria-disabled='true'] {
            display: none;

            @include media(l) {
                display: flex;
                justify-content: center;
                opacity: 1 !important;
                background-color: $colorWhiteBackground;

                &::before {
                    display: block !important;
                    color: $colorWhite;
                    margin: auto auto;
                    content: '';
                    background-image: url('#{$assetPath}img/chevron.svg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    opacity: 1;
                    z-index: 10;
                    height: 38px;
                    width: 38px;
                    @include media(l) {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            #{$root}--White & {
                @include media(l) {
                    background-color: $colorGray200 !important;
                }
            }
        }

        &--Prev {
            left: -10px;

            &:before {
                transform: rotate(-180deg);
            }

            @include media(s) {
                left: 0;
            }

            @include media(l) {
                right: 50px;
                left: unset;
            }
        }


        &--Next {
            right: -10px;
            left: unset;

            @include media(s) {
                right: 0;
            }
        }
    }

}
